html,
body {
  padding: 0;
  margin: 0;
  background: #fff;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.mobile-menu-is-open {
  position: fixed;
}

.modal-open {
  overflow: hidden;
}
